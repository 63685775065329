.info-icon {
  width: 18px;
  height: 18px;
  margin-left: 2px;
  position: relative;
  top: 2px;
  padding-top: 5px;
}

.super {
  vertical-align: super;
  font-size: 8px;
}

.banner {
  width: 100%;
}

.check-icon {
  color: white;
  position: relative;
  top: 10px;
  left: 12px;
  font-size: 60px;
}

.logo-container,
.stepper-container {
  position: relative;
  background-color: #ffffff;
  z-index: 1;
  top: 0;
  left: 95px;
  height: auto;
  display: flex;
  justify-content: center;
  width: 100%;
  flex-direction: column;
}

.stepper-container {
  position: relative;
  left: -90px;
  height: 70px;
  z-index: 1;
  margin-top: 75px;
}

@media (min-width: 1536px) and (max-width: 2560px) {

  .logo-container,
  .stepper-container {
    width: 520px;
  }
}

@media (min-width: 600px) and (max-width: 720px),
(min-width: 721px) and (max-width: 900px) {

  .logo-container,
  .stepper-container {
    max-width: 75% !important;
    left: -10px;
  }
}

@media (min-width: 900px) and (max-width:901px) {

  .logo-container,
  .stepper-container {
    max-width: 8% !important;
  }
}

@media (max-width: 600px) {

  .logo-container,
  .stepper-container {
    max-width: 75% !important;
    left: -10px;
  }
}

@media (min-width: 901px) and (max-width:1120px) {

  .logo-container,
  .stepper-container {
    max-width: 50% !important;

  }
}


@media (min-width: 2560px) {

  .logo-container,
  .stepper-container {
    width: 520px;
  }
}

.logo {
  margin-top: 25px;
  margin-bottom: 25px;
}

.stepper {
  position: sticky;
}

.premiumLogo {
  display: none;

}

.bannerLogo,
.donePage {
  margin: auto 0;
  margin-top: 25px;
  margin-bottom: 25px;
  text-align: center;
  z-index: 100;
}

@media (max-width: 380px) {
  .bannerLogo>img {
    max-width: 90vw !important;
  }
}

@media (max-width: 400px) {
  .donePage>img {
    max-width: 80vw !important;
    margin-right: -16px !important;
    z-index: 100;
  }
}

@media screen and (max-width: 899px) {
  .banner {
    display: none;
  }

  .premiumLogo {
    display: flex;

  }

  .originalLogo {
    display: none;
  }

}

@media (min-width: 900px) and (max-width: 1130px) {
  .banner {
    max-width: 100%;
  }
}

.form-scroll {
  overflow-y: scroll;
}

.form-scroll::-webkit-scrollbar {
  display: none;
}

.form-scroll {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.PhoneInputInput {
  border: none;
  font-family: "OpenSansRegular" !important;
  height: 28px;
  background: transparent !important;
  font-size: "40px" !important;
  position: relative;

}

.PhoneInputInput:focus-visible {
  outline: none;
}

.PhoneInputCountryFlag:focus-visible {
  border-color: #BABFC5;
}

.PhoneInputCountryIcon--border {
  box-shadow: none !important;
}

.PhoneInputCountrySelectArrow {
  margin-left: 20px !important;
  width: 10px !important;
  height: 10px !important;
  color: #8C9196 !important;
  border-color: #8C9196 !important;
  background-color: #8C9196 !;
  opacity: 1 !important;
}

.PhoneInputCountrySelect {
  width: 90% !important;
}


@media (min-width: 300px) and (max-width: 1200px) {
  .PhoneInputCountrySelectArrow {
    margin-right: 30px;
  }
}

@media (min-width: 1024px) and (max-width: 1200px) {
  .PhoneInputCountrySelectArrow {
    margin-right: 20px;
  }
}

.PhoneInputCountryIconImg {
  width: 40px !important;
  height: 21px !important;
  background-color: #fafafa;
}


.PhoneInput {
  height: 48px;
  margin-top: 22px !important;
  background: transparent !important;
}

.privacy-terms a {
  text-decoration: none;
  color: #50B748;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;
  box-shadow: 0 0 0px 1000px #ffffff inset !important;
}

.navybg {
  width: 100%;
  margin-top: -43px;
  height: 48.5px;
  z-index: -1,
}

@media (max-width:576px) {
  .navybg {
    margin-top: -80px;
    height: 86px;
  }
}

@media (min-width:576px) and (max-width:599px) {
  .navybg {
    margin-top: -48px !important;
    height: 65px !important;
  }
}

@media (min-width: 351px) and (max-width: 600px) {
  .navybg {
    margin-top: -68px !important;
    height: 72px !important;
  }
}

@media (max-width:351px) {
  .MuiBox-root.footer-icons {
    margin-top: 0 !important;
  }
}

@media (max-width: 350px) {
  .navybg {
    margin-top: -86px !important;
    height: 90px !important;

  }
}



@media (min-width: 600px) and (max-width: 720px) {
  .navybg {
    margin-top: -47px;
    height: 53px;
  }

  .footer-text {
    width: 230px;

  }
}

@media (min-width: 345px) and (max-width: 599px) {
  .navybg {
    margin-top: -59px;
    height: 65px;
  }
}

.footerXL {
  position: relative;
  left: 0;
  bottom: -1px;
  right: 0;
}

.registerFirstForm {
  height: auto;
}

@media (min-height:1000px) {
  .footerXL {
    position: fixed !important;
    left: -1px;
    bottom: -2px;
    right: -1px;
  }

  .registerFirstForm {
    height: 1000px;
  }


}


.merchant-tooltip {
  position: relative;
  display: inline-block;
  top: -8px !important;
}

.portalTooltip {
  width: 'auto' !important;
  font-size: 14px;
  font-family: "OpenSansRegular" !important;
}

.footer-content {
  color: #202223;
}

@media (min-width: 576px) and (max-width: 1400px) {
  .MuiContainer-root.footer-padding {
    padding-right: 0px !important;
    padding-left: 0px !important;
  }
}

@media (min-width: 1400px) {
  .MuiContainer-root.footer-padding {
    padding-right: 0px !important;
    padding-left: 0px !important;
  }
}

.open-sans {
  font-family: "OpenSansRegular" !important;
}

@media (max-width: 374px) {
  .MuiTypography-root.copyright {
    font-size: 13px !important;
  }
}


.MuiTypography-root.white {
  color: #ffffff !important;
  line-height: 1.625 !important;
}

.footer-download-img {
  height: 36px !important;
  width: 126px !important;
}

@media (min-width:768px) and (max-width: 1200px) {
  .footer-download-img {
    height: 31px !important;
    width: 110px !important;
  }

  .playstore {
    margin-left: 8px;
  }
}

.extraspace {
  padding-left: 0px !important;
}

@media (max-width: 768px) {
  .footer-download-img {
    height: 28px !important;
    width: 90px !important;
  }

  .playstore {
    margin-left: 4px;
  }
  .extraspace {
    padding-left: 24px !important;
  }
}

@media (max-width: 440px) {
  .topMargin {
    margin-top: 12px;
  }
}

@media (min-width:601px) and (max-width: 1024px) {
  .footer-first {
    height: 115px !important;
  }
}

@media (max-width:602px) {

  /* .footer-first {
      padding-bottom: 20px;
    } */
  .footer-second {

    font-family: "OpenSansRegular" !important;
  }

  /* .footer-icons {
      padding-top: 8px;
    } */
}

@media (max-width:350px) {
  .footer-text {
    width: 220px;
  }
}

/* @media (min-width:721px){
  .footer-icons {
    margin-bottom : 3px;
  }
  .footer-text {
    margin-bottom: 3px;
  }
} */




@media (max-width:600px) {
  .footer-download-img {
    height: 36px !important;
    width: 126px !important;
  }
}

@media (width:600px) {
  .footer-download-img {
    height: 28px !important;
    width: 90px !important;
  }
}


.MuiTypography-root.link-text {
  letter-spacing: 0 !important;
}


.MuiTypography-root.footer-text {
  letter-spacing: 0 !important;
}

.MuiTypography-root.link-text {
  letter-spacing: 0 !important;
}


.bookDemoBtn {
  border-radius: 8px;
  border: 1px solid #50b848;
  color: #50b848;
  font-family: "Open Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  cursor: pointer;
  width: 166px;
  height: 42px;
  flex-shrink: 0;
  /* margin-top: 24px;
  margin-bottom: 32px; */
}

.book-demo-text:hover {
  color: #ffffff !important;
}

.bookDemoBtn:hover {
  background-color: #3cac33;
  color: #ffffff;
}

.download-apps {
  display: flex;
  gap: 24px;
}

.social-icons {
  width: 28px !important;
  height: 28px !important;
}

.appstore {
  width: 131px !important;
  height: 36px !important;
}

.playstore {
  width: 131px !important;
  height: 36px !important;
}

.download-apps {
  display: flex;
  gap: 20px;
}


@media screen and (max-width:600px) {
  .bookDemoBtn {
    width: 100%;
    height: 32px;
    font-size: 16px;
  }

  .download-apps {
    display: flex;
    gap: 16px;
  }

  .appstore {
    width: 162px !important;
    height: 44px !important;
  }

  .playstore {
    width: 162px !important;
    height: 44px !important;
  }
}


@media screen and (max-width:350px) {
  .download-apps {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 16px;
  }
}

.footer-logo {
  width: 114px;
  height: 20px;
}

.footer-right {
  width: 24px !important;
  height: 24px !important;
}

@media screen and (min-width:1366px) {
  .social-icons {
    width: 36px !important;
    height: 36px !important;
  }

  .footer-right {
    width: 28px !important;
    height: 28px !important;
  }

  .appstore {
    width: 166px !important;
    height: 48px !important;
  }

  .playstore {
    width: 166px !important;
    height: 48px !important;
  }

  .footer-logo {
    width: 205px;
    height: 36px;
  }
}

.terms {
  text-decoration: none;
  cursor: pointer;
  color: #000000;
}

.thankYouBlock {
  position: absolute;
  background-color: #F7EFE5;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
  border-radius: 16px;
  gap: 36px;
  bottom: 60px;
  left: 0;
  right: 0;
  margin: 0 auto;
  padding: 24px;
  width: 456px;
  height: auto;
  flex-shrink: 0;
}

.contentBlock {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 24px;
}

.thankYouText {
  color: #000;
  font-family: "SourcePro";
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  font-size: 32px;
  width: 100%;
}

.thankYouContent {
  color: #616161;
  font-family: "SourcePro";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: normal;
  width: 348px;
}

@media screen and (max-width: 767px) {
  .thankYouBlock {
    border-radius: 16px;
    width: 269px;
    height: auto;
    min-height: 138px;
    flex-shrink: 0;
    bottom: 37px;
    left: 0;
    right: 0;
    margin: 0 auto;
    padding: 16px;
    gap: 16px;
  }

  .contentBlock {
    gap: 8px;
  }

  .thankYouText {
    font-size: 18px;
  }

  .thankYouContent {
    font-size: 14px;
    width: 198px;
    line-height: 24px;
    /* 171.429% */
  }
}

@media screen and (min-width: 768px) and (max-width: 1200px) {

  .thankYouBlock {
    border-radius: 16px;
    width: 357px;
    min-height: 154px;
    height: auto;
    flex-shrink: 0;
    bottom: 48px;
    left: 0;
    right: 0;
    margin: 0 auto;
    padding: 24px;
    gap: 24px;
  }

  .contentBlock {
    gap: 12px;
  }

  .thankYouText {
    font-size: 24px;
  }

  .thankYouContent {
    font-size: 16px;
    width: 261px;
    line-height: 30px;
  }
}

@media (max-width:370px) {
  .appstore {
    width: 144px !important;
    height:44px !important;
  }

  .playstore {
    width: 144px !important;
    height: 44px !important;
  }

  .copy-text {
    font-size: 13px !important;
  }
}