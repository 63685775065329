body.active-modal {
    overflow-y: hidden;
}

body.active-cancelmodal {
    overflow-y: hidden;
}

body.active-alertPopup {
    overflow-y: hidden;
}

body.active-confirmPopup {
    overflow-y: hidden;
}

body.active-editAdd-modal{
    overflow-y: hidden;
}

body.active-addOnModal {
    overflow-y: hidden;
}

body.active-addOnLocModal {
    overflow-y: hidden;
}

body.active-addonstatusmodal {
    overflow-y: hidden;
}

.btn-modal {
    padding: 10px 20px;
    display: block;
    margin: 100px auto 0;
    font-size: 18px;
}

.modal, .cancelmodal, .alertmodal, .confirmmodal, .addressEditmodal, .addonmodal, .addonlocmodal,
.addonStatusmodal, .overlay {
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    z-index: 1;
}

.overlay {
    background: #2E4455;
    opacity: 0.5;
}
.modal-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    line-height: 1.4;
    background: #FFFFFF;
    border-radius: 8px; 
    max-width: 606px;
    min-width: 342px;
    z-index: 100;
    /* min-height: 501px; */
}

.modal-cancel-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    line-height: 1.4;
    background: #FFFFFF;
    border-radius: 8px;
    width: 55%;
    max-width: 606px;
    min-width: 342px;
    max-height: auto;
    z-index: 100;
    overflow-y: auto;
    /* min-height: 501px; */
}

@media (max-height:750px) {
    

    .modal-cancel-content {
    max-height: 600px;
    }
}


.alert-modal-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    line-height: 1.4;
    background: #FFFFFF;
    border-radius: 8px;
    /* width: 450px;
    max-width: 606px;
    min-width: 342px; */
    width: 551px;
    min-height: 257px;
    z-index: 100;
}

.close-modal {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 5px 7px;
}

.confirm-popup-content {
    position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        line-height: 1.4;
        background: #FFFFFF;
        border-radius: 8px;
        /* width: 450px;
        max-width: 606px;
        min-width: 342px; */
        width: 551px;
        min-height: 257px;
        z-index: 100;
}

.addon-modal-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    line-height: 1.4;
    background: #FFFFFF;
    border-radius: 8px;
    max-width: 606px; 
    min-width: 342px;
    width: 55%;
    min-height: 257px;
    z-index: 100;
}

.addressEdit-modal-content {
    position: absolute;
    top: 50%;
    left: 50%;
    /* margin: 24px; */
    padding: 12px;
    transform: translate(-50%, -50%);
    line-height: 1.4;
    background: #FFFFFF;
    border-radius: 8px;
    width: 50vw;
    max-width: 500px;
    min-width: 300px;
    height: auto;
    max-height: 95vh;
    min-height: 257px;
    overflow-y: auto;
    z-index: 100;
    /* z-index: 1 !important; */
}

.addon-modal-loc-content {
    position: absolute;
        top: 50%;
        left: 50%;
        /* margin: 24px; */
        padding: 12px;
        transform: translate(-50%, -50%);
        line-height: 1.4;
        background: #FFFFFF;
        border-radius: 8px;
        max-width: 1037px;
        width: 70%;
        min-width: 320px;
        height: auto;
        max-height: 95vh;
        min-height: 257px;
        overflow-y: auto;
        z-index: 100;
}

.addon-status-modal-content {
    position: absolute;
    top: 50%;
    left: 50%;
    /* margin: 24px; */
    padding: 12px;
    transform: translate(-50%, -50%);
    line-height: 1.4;
    background: #FFFFFF;
    border-radius: 8px;
    max-width: 582px;
    width: 55%;
    min-width: 344px;
    height: auto;
    max-height: 95vh;
    min-height: 257px;
    overflow-y: auto;
    z-index: 100;
}

.semi-bold{
    font-family: OpenSansRegular !important;
    font-weight: 600 !important;
}
.opensans-regular{
    font-family: OpenSansRegular !important;
}
.opensans-medium{
    font-family: OpenSansMedium;
}
.opensans-bold{
    font-family: OpenSansMedium;
}
.MuiTypography-root.welcome{
    font-family: "Typografix";
    word-spacing: 0.28px;
    font-weight: 500;
}

.MuiButton-outlined.browseBtn {
    border-color: #50B748 !important;
    color: #50B748 !important;
    border-radius: 4px;
    font-family: OpenSansSemiBold !important;
}



.MuiButton-outlined.addons {
    border-color: #50B748 !important;
    color:#50B748 !important;
    border-radius: 4px;
    font-family: OpenSansRegular !important;

}
.MuiButton-outlined.addons:hover,
.MuiButton-contained.upgrade:hover {
    background-color: #3CAC33 !important;
    color: #FFFFFF !important;
    border-radius: 4px;
    font-family: OpenSansRegular !important;
}

.MuiButton-contained.upgrade {
    background-color: #50B748 !important;
    color: #FFFFFF !important;
    border-radius: 4px;
    font-family: OpenSansRegular !important;
}

.MuiButton-text.editCancel {
    color: #7F7F7F !important;
}

.MuiButton-outlined.addons:hover, .MuiButton-outlined.upgrade:hover{
    /* background-color: #3CAC33 !important; */
    opacity: 0.75;
    /* color:#FFFFFF !important; */
    border-radius: 4px;
    font-family: OpenSansRegular !important;
}
.MuiButton-outlined.upgrade{
    background-color: #FFFFFF !important;
    color: #50B748 !important;
    border-radius: 4px;
    font-family: OpenSansRegular !important;
    border-color: #50B748 !important;
    text-decoration: none;

}

.MuiButton-contained.removeCard {
    background-color: #FFFFFF !important;
    color: #202223 !important;
    border-radius: 4px;
    font-family: OpenSansRegular !important;
}

.MuiButton-contained.removeCard:hover {
    background-color: #3CAC33 !important;
    color: #FFFFFF !important;
    border-radius: 4px;
    font-family: OpenSansRegular !important;
}



.MuiButton-outlined.addOn_Add:hover {
    background-color: #3CAC33 !important;
    color:#FFFFFF !important;
    border-radius: 4px;
    font-family: OpenSansRegular !important;
}

.MuiButton-outlined.addOn_Add {
    background-color: #FFFFFF !important;
    color: #50B748 !important;
    border-radius: 4px;
    font-family: OpenSansMedium !important;
    border-color: #50B748 !important;
    text-decoration: none;

}


/* .MuiButton-outlined.addons:hover, .MuiButton-outlined.upgrade:hover{
    opacity: 0.75;
    color:#FFFFFF !important;
    border-radius: 4px;
    font-family: OpenSansRegular !important;
} */

.MuiButton-contained.authButton:hover {
    background-color: #3CAC33 !important;
    color: #FFFFFF !important;
    border-radius: 4px;
}

.MuiButton-contained.cancelButton:hover {
    background-color: #3CAC33 !important;
    color: #FFFFFF !important;
    border-radius: 4px;
}

.MuiButton-contained.cancelButton {
    background-color: #50B748 !important;
    color: #FFFFFF !important;
    border-radius: 4px;
    font-size: 15px;
    font-family: OpenSansSemiBold !important;
}

.btn-txt{
    color:#50B748 !important;
    text-decoration: none !important;
    text-transform: capitalize !important;
    font-weight: 600 !important;
    font-family: OpenSansRegular !important;
} 
.MuiTypography-root.upgrade, .MuiTypography-root.authButton, .MuiTypography-root.title{
    font-family: OpenSansRegular !important;
    font-weight: 600;
}
.MuiTypography-root.pricingcard-duration, .MuiTypography-root.pricingcard-specification {
    font-size: 14px !important;
    font-family:OpenSansRegular !important;
  }
.MuiTypography-root.pricingcard-description {
    font-size: 16px !important;
    font-family: OpenSansRegular !important;
}
@media (min-width:1200px){
     .MuiTypography-root.pricingcard-duration, .MuiTypography-root.pricingcard-description, .MuiTypography-root.pricingcard-specification {
        font-size: 16px !important;
    }
}
.noHover{
    pointer-events: none;
}
.price-card:hover .MuiButton-root{
    background-color: #FFFFFF;
    color: #50B748;
}
.MuiTypography-root.title{
    color:#7F7F7F;
}
.price-card:hover .MuiTypography-root.title{
    color:#FFFFFF;
}

.price-card:hover {
    color: #FFFFFF !important;
}

/* .MuiTypography-root.title{
    color:#7F7F7F;
}
.MuiTypography-root.title:hover{
    color:#FFFFFF;
} */
.MuiToggleButton-root {
    text-transform: none !important;
    letter-spacing: 0.5;
    width: 160px;
    height: 54px;
    font-family: OpenSansRegular !important;
    font-weight: 650 !important;
}
.MuiToggleButton-root.Mui-selected {
    color: #50B748 !important;
    background-color: #eef7ed !important;
}

.MuiToggleButton-root.Mui-selected:hover {
    background-color: #c2f7bc !important;
}
.pricingcard-specification {
    float: left !important;
}

.pricingcard-specification > ul {
    float: left !important;
}

.price-cards {
    color: #202223 !important;
    /* max-width: 362px !important;
    align-items: center !important;
    text-align: center; */
}

.tableContent {
    /* font-size: 16px !important; */
    font-family: OpenSansRegular !important;
    color: #202223 !important;
}

.tableHeader {
    font-size: 16px !important;
    color: #202223 !important;
}

.tableNote {
    font-size: 14px !important;
    color: #202223 !important;
    font-family: OpenSansRegular !important;
    font-style: italic;
    line-height: 2;
}


.tooltip {
    position: relative;
    display: inline-block;
    font-size: 14px;
    cursor: pointer;
}

.tooltip .tooltiptext {
    visibility: hidden;
    width: 150px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    top: 150%;
    left: -250%;
    margin-left: -60px;
}

.tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent black transparent;
}

.tooltip:hover .tooltiptext {
    visibility: visible;
    z-index: 0;
}

.MuiTableCell-root,
.table-features {
    color: #202223 !important;
    font-size: 14px;
    font-family: OpenSansRegular !important;
}

.tool {
    display: none;
}

@media (max-width: 360px),
(min-width:609px) and (max-width:768px),
(min-width:1200px) and (max-width: 1400px) {
    .tool {
        display: inline;
    }
}

.cancel-content {
    font-family: 'OpenSansRegular' !important;
    max-height: 600px;
}

.cancel-content > div {
    line-height: 2;
    margin-top: 10px;
    font-family: 'OpenSansRegular'
}

.addon-content > div {
    line-height: 2;
    margin-bottom: 10px;
    font-family: 'OpenSansRegular'
}

.addon-loc-content>div {
    line-height: 2;
    font-family: 'OpenSansRegular'
}

.link-button {
    text-decoration: none;
    color: #50B748;  
    font-family: 'OpenSansRegular'
}

.modal-height-set {
    height: auto !important;
}

.modal-content-height-set {
    height: auto !important;
}

@media (max-height:780px) {
    .modal-height-set {
        height: 597px !important;
    }
    .modal-content-height-set {
        height: 400px !important;
    }
}

@media (max-height:680px) {
    .modal-height-set {
        height: 497px !important;
    }
        .modal-content-height-set {
            height: 310px !important;
        }
}

@media (max-height:540px) {
    .modal-height-set {
        height: 400px !important;
    }

    .modal-content-height-set {
        height: 200px !important;
    }
}

@media (max-height:400px) {
    .modal-height-set {
        height: 300px !important;
    }

    .modal-content-height-set {
        height: 140px !important;
    }
}


.PhoneInputCountryIconImg {
    width: 35px;
    height: 16px;
    background-color: #fafafa;
}

.cartItemCount {
    height: 15px;
    width: 15px;
    border-radius: 50px;
    background-color: red;
    color: #FFFFFF;
    font-size: 9px;
    margin-left: -15px;
    margin-top: -13px;
}

.qty-count {
    font-size: 16px;
    color: #202223;
    letter-spacing: 0.14px;
    text-align: center;
    opacity: 1;
    top: -7px;
    position: relative;
}

.qtyBtn > button {
    min-height: 30px;
    margin-top: -15px;
}

.cart_block {
    max-height: 400px;
    overflow-y: auto;
}

.fileuploadText {
    float: left;
    margin: auto 0;
    vertical-align: middle;
    line-height: 2.8;
    margin-right: 70px;
}

.drop_area_field {
    border: dashed 1px #BABFC5;
    padding-bottom: 15px;
    padding-top: 15px;
    padding-left: 13.5px;
    border-radius: 6px;
    /* height: 69px; */
}

.drop_area_field > img {
    margin-top:9px;
}

.MuiRadio-root > span {
    font-size: 14px !important;
}

/* #compareTable::-webkit-scrollbar {
    display: none;
} */

.compareTableHeader {
    z-index: 1 !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}